import React, {useCallback, useEffect, useState} from 'react';
import Verify from "assets/UserAssets/verify.svg";
import OptionCircles from "assets/UserAssets/optionCircles.svg";
import ViewContainer from "components/ViewContainer";
import ImageCol from "components/ImageCol";
import ContentCol from "components/ContentCol";
import {useTypedSelector} from "helpers/reducers";
import {SessionControllerState} from "store/sessionController/SessionControllerReducer";
import Loader from "components/Loader";
import {RouteComponentProps, withRouter} from "react-router";
import {getNextStep, getPrevStep} from "helpers/utils";
import {AppControllerState} from "store/appControler/AppControllerReducer";
import AppControllerAction from "store/appControler/AppControllerAction";
import {useDispatch} from "useDispatch";
import ActionButtons from "pages/verification/components/ActionButtons";
import UserExists from "pages/verification/components/UserExists";
import { useForm } from 'react-hook-form';
import useFoxentryAddressWhisper from 'helpers/hooks/foxentry/useFoxentryAddressWhisper';
import SessionControllerAction from 'store/sessionController/SessionControllerAction';
import PurposeItemContainer from 'pages/purpose/components/PurposeItemContainer';
import PurposeItem from 'pages/purpose/components/PurposeItem';
import clsx from 'clsx';
import { debounce } from "lodash";
import LustrationGreen from "assets/UserAssets/lustrationGreen.svg";

interface Params {}

interface Props extends RouteComponentProps<Params> {}

const VerificationSummary = (props: Props) => {
    const sessionController: SessionControllerState = useTypedSelector(state => state.sessionController)
    const appController: AppControllerState = useTypedSelector(state => state.appController);
    const [activeOptionsValue, setActiveOptionsValue] = useState("");
    const [addressError, setAddressError] = useState(false);
    const [addressGoogle, setAddressGoogle] = React.useState("");
    const [nextStepDisabled, setNextStepDisabled] = useState<boolean>(true);
    const mailingAddress = useTypedSelector(state => state.sessionController.mailingAddress);
    const dispatch = useDispatch();
    const addressForm = useForm();
    const [isValidAddress, setIsValidAddress] = useState(false); 

    
    useEffect(() => {
        if (mailingAddress?.has_mailing_address !== null) {
            setActiveOptionsValue(mailingAddress?.has_mailing_address ? "Ano" : "Ne");
        }
    }, [mailingAddress?.has_mailing_address, activeOptionsValue]);
    
    useEffect(() => {
        if (mailingAddress.has_mailing_address) {
            addressForm.setValue('address', mailingAddress.address);
        }
    }, []);

    useEffect(() => {
        if (sessionController.mailingAddress.has_mailing_address) {
            if (sessionController.mailingAddress.address === "" || sessionController.mailingAddress.address === null || addressError) {
                setNextStepDisabled(true);
            } else {
                setNextStepDisabled(false)
            }
        } else if (sessionController.mailingAddress.has_mailing_address === false) {
            setNextStepDisabled(false);

        } else {
            setNextStepDisabled(true);
        }
    })

    const onPrevStepHandler = () => {
        const prevStep = getPrevStep(appController.current_step);
        dispatch(AppControllerAction.setCurrentStep(prevStep.newStep));
        props.history.push(prevStep.page);
    }

    const onNextStepHandler = () => {
        const nextStep = getNextStep(appController.current_step);
        dispatch(AppControllerAction.setCurrentStep(nextStep.newStep))
        props.history.push(nextStep.page);
    }

    const { response, showWhisperer, setShowWhisperer, debounceEventHandler, fetchAddress } = useFoxentryAddressWhisper();

    useEffect(() => {
        console.log(showWhisperer)
        console.log(response)
    }, [showWhisperer, response])


    const onQuestionAnswerHandler = (event) => {
        const value = event.target.value === 'Ano';
        dispatch(SessionControllerAction.setUserMailingAddress({
            name: "has_mailing_address",
            value: value
        }))
        setActiveOptionsValue(event.target.value)
    }

    const handleChange = useCallback(
        debounce((value: string) => {
          setAddressGoogle(value);
          fetchAddress('/location/search', value);
        }, 550),
        []
      );
      
      const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsValidAddress(false);
        handleChange(event.target.value);
      };
    

      const handleOnclick = (data) => {
        setShowWhisperer(false);

        const isValid = data.full && data.street && data.city;
        setIsValidAddress(isValid);

        if (!data.full) {
            setAddressError(true)
        }
    
        if (!data.street || !data.city) {
            setAddressError(true);
        } else {
            setAddressError(false);
        }
    
        // setAddressError(false);
    
        const street = data.street || '-';
        const houseNumber = data.number?.full || '-';
        const city = data.city || '-';
        const zip = data.zip || '-';
    
        const formattedAddress = `${street} ${houseNumber}, ${city} ${zip}`;
    
        addressForm.setValue('address', formattedAddress);
    
        dispatch(SessionControllerAction.setUserMailingAddress({ name: 'street', value: data.street || '' }));
        dispatch(SessionControllerAction.setUserMailingAddress({ name: 'house_number', value: data.number?.full || '' }));
        dispatch(SessionControllerAction.setUserMailingAddress({ name: 'city', value: data.city || '' }));
        dispatch(SessionControllerAction.setUserMailingAddress({ name: 'postal_code', value: data.zip || '' }));
        dispatch(SessionControllerAction.setUserMailingAddress({ name: 'address', value: formattedAddress }));
    }

    const handleBlur = () => {
        setTimeout(() => {
            setShowWhisperer(false);
        }, 550);
    }

    if (!sessionController) {
        return <Loader/>
    }

    return (
        <ViewContainer class={"mt-3 mt-lg-0"}>
            <ImageCol lg={3} image={Verify}/>
            <ContentCol lg={6} class={"summary-textBox"} style={{ marginTop: "100px" }}>
                <div className="summary-box">
                    <img src={OptionCircles} className="summary-optionsCircles" alt=""/>
                    <h1 className="summary-userName">{sessionController.user?.first_name + ' ' + sessionController.user?.last_name}</h1>
                    <p className="summary-greyText summary-ic">{'IČ ' + sessionController?.user?.company_number}</p>
                    <div className="summary-address">
                        <p className="summary-greyText summary-street">{sessionController?.user?.address?.street + ' ' + sessionController?.user?.address?.house_number}</p>
                        <p className="summary-greyText summary-city">{sessionController?.user?.address?.city}</p>
                    </div>
                    {sessionController?.userExists?.exists && (
                        <UserExists/>
                    )}
                </div>
                {
                    !sessionController?.userExists?.exists && (
                        <form style={{position: 'relative', zIndex: '1'}}>
                            <ul className="calcSummary-list Question-list Purpose-list">
                                <PurposeItemContainer title={<><b>Poštu mi zasílejte jinam</b> - mám jinou korespondenční adresu</>}>
                                    <PurposeItem
                                        name={'purpose2'}
                                        value={'Ano'}
                                        objectItem={'has_mailing_address'}
                                        label={'Ano'}
                                        activeValue={activeOptionsValue}
                                        setActiveValue={setActiveOptionsValue}
                                        onClick={onQuestionAnswerHandler}/>
                                    <div className={clsx("otherInputWrap", mailingAddress?.has_mailing_address && "show")} style={addressError && activeOptionsValue === 'Ano' ? {marginBottom: '60px'} : {marginBottom: '0px'}}>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', maxWidth: '100%' }}>
                                            <input
                                                type="text"
                                                {...addressForm.register('address')}
                                                onChange={handleOnChange}
                                                onBlur={handleBlur}
                                                style={{ flex: 1, maxWidth: "90%" }}
                                            />
                                            {isValidAddress && (
                                                <img 
                                                    src={LustrationGreen} 
                                                    height={24} 
                                                    width={24} 
                                                    className="lustration-icon"
                                                    style={{ flexShrink: 0, height: "24px", maxHeight: "24px" }}
                                                    alt="Valid Address"
                                                />
                                            )}
                                        </div>
                                            {showWhisperer && response?.length && (
                                                <div>
                                                    {response?.map((result, index) => (
                                                        <div
                                                            key={index}
                                                            onClick={() => handleOnclick(result)}
                                                            className={'autocomplete'}>
                                                            {`${result.street || ''} ${result.number?.full || ''} ${result.city || ''} ${result.zip || ''}`}</div>
                                                    ))}
                                                </div>
                                            )}

                                            {addressError && (
                                                <p className={"error address-error"}>
                                                    Zadejte adresu ve správnem formátu: ulice číslo domu, město
                                                </p>
                                            )}

                                    </div>
                                    <PurposeItem
                                        name={'purpose2'}
                                        value={'Ne'}
                                        objectItem={'has_mailing_address'}
                                        label={'Ne'}
                                        activeValue={activeOptionsValue}
                                        setActiveValue={setActiveOptionsValue}
                                        onClick={onQuestionAnswerHandler}/>
                                </PurposeItemContainer>
                            </ul>
                        </form>
                    )
                }

                {!sessionController?.userExists?.exists && (
                    <ActionButtons nextStep={onNextStepHandler} prevStep={onPrevStepHandler} nextStepDisabled={nextStepDisabled}/>
                )}
            </ContentCol>
        </ViewContainer>
    );
};

export default withRouter(VerificationSummary);