import {AppCurrentStep} from "types/enums/AppCurrentStep";
import LinkTo from "helpers/LinkTo";
import {verificationType} from "types/enums/VerificationType";
import LustrationGreen from "assets/UserAssets/lustrationGreen.svg";
import LustrationNeutral from "assets/UserAssets/lustrationNeutral.svg";
import LustrationRed from "assets/UserAssets/lustrationRed.svg";
import {AppControllerState} from "store/appControler/AppControllerReducer";

export const getNextStep = (currentStep) => {
    switch (currentStep) {
        case AppCurrentStep.Calculation:
            return {
                page: LinkTo.calculationSummary(),
                newStep: AppCurrentStep.CalculationSummary
            }   
        case AppCurrentStep.CalculationSummary:
            return {
                page: LinkTo.verification(),
                newStep: AppCurrentStep.Verification,
            };
        case AppCurrentStep.Verification:
            return {
                page: LinkTo.verificationSummary(),
                newStep: AppCurrentStep.VerificationSummary
            }
        case AppCurrentStep.VerificationSummary:
            return {
                page: LinkTo.verificationQuestions(),
                newStep: AppCurrentStep.VerificationQuestions
            }
        case AppCurrentStep.VerificationQuestions:
            return {
                page: LinkTo.purpose(),
                newStep: AppCurrentStep.Purpose
            }
        case AppCurrentStep.Purpose:
            return {
                page: LinkTo.spendingReport(),
                newStep: AppCurrentStep.SpendingReport
            }
        // case AppCurrentStep.PurposeRepeat:
        //     return {
        //         page: LinkTo.spendingReport(),
        //         newStep: AppCurrentStep.SpendingReport
        //     }
        case AppCurrentStep.SpendingReport:
            return {
                page: LinkTo.registration(),
                newStep: AppCurrentStep.RegisterAccount
            }
        // case AppCurrentStep.Address:
        //     return {
        //         page: LinkTo.registration(),
        //         newStep: AppCurrentStep.RegisterAccount
        //     }
        case AppCurrentStep.RegisterAccount:
            return {
                page: LinkTo.sendRequest(),
                newStep: AppCurrentStep.SendRequest
            }
        case AppCurrentStep.VerificationQuestionsFail:
            return {
                page: LinkTo.home(),
                newStep: AppCurrentStep.Start
            }
        case AppCurrentStep.SendRequest:
            return {
                page: LinkTo.finish(),
                newStep: AppCurrentStep.Finish
            }

        default:
            return {
                page: LinkTo.home(),
                newStep: AppCurrentStep.Start
            };
    }
}

export const getCurrentStep = (path) => {
    switch (path) {
        case LinkTo.calculation():
            return AppCurrentStep.Calculation
        case LinkTo.calculationSummary():
            return  AppCurrentStep.CalculationSummary
        case LinkTo.verification():
            return  AppCurrentStep.Verification
        case LinkTo.verificationSummary():
            return AppCurrentStep.VerificationSummary
        case LinkTo.verificationQuestions():
            return  AppCurrentStep.VerificationQuestions
        case LinkTo.purpose():
            return  AppCurrentStep.Purpose
        // case LinkTo.purposeRepeat():
        //     return AppCurrentStep.PurposeRepeat
        case LinkTo.spendingReport():
            return AppCurrentStep.SpendingReport
        // case LinkTo.address():
        //     return AppCurrentStep.Address
        case LinkTo.registration():
            return AppCurrentStep.RegisterAccount
        case LinkTo.sendRequest():
            return AppCurrentStep.SendRequest
        case LinkTo.verificationFailed():
            return AppCurrentStep.VerificationQuestionsFail
        case LinkTo.finish():
            return AppCurrentStep.Finish

        default:
            return AppCurrentStep.Start
    }
}

export const getPrevStep = (currentStep) => {
    switch (currentStep) {
        case AppCurrentStep.Verification:
            return {
                page: LinkTo.calculationSummary(),
                newStep: AppCurrentStep.CalculationSummary
            }
        case AppCurrentStep.VerificationSummary:
            return {
                page: LinkTo.verification(),
                newStep: AppCurrentStep.Verification
            }
        case AppCurrentStep.VerificationQuestions:
            return {
                page: LinkTo.verificationSummary(),
                newStep: AppCurrentStep.VerificationSummary
            }
        case AppCurrentStep.Purpose:
            return {
                page: LinkTo.verificationQuestions(),
                newStep: AppCurrentStep.VerificationQuestions
            }
        // case AppCurrentStep.PurposeRepeat:
        //     return {
        //         page: LinkTo.purpose(),
        //         newStep: AppCurrentStep.Purpose
        //     }
        case AppCurrentStep.SpendingReport:
            return {
                page: LinkTo.purpose(),
                newStep: AppCurrentStep.Purpose
            }
        // case AppCurrentStep.Address:
        //     return {
        //         page: LinkTo.spendingReport(),
        //         newStep: AppCurrentStep.SpendingReport
        //     }
        case AppCurrentStep.RegisterAccount:
            return {
                page: LinkTo.spendingReport(),
                newStep: AppCurrentStep.SpendingReport
            }
        case AppCurrentStep.SendRequest:
            return {
                page: LinkTo.registration(),
                newStep: AppCurrentStep.RegisterAccount
            }
        case AppCurrentStep.VerificationQuestionsFail:
            return {
                page: "",
                newStep: AppCurrentStep.Null
            }

        default:
            return {
                page: LinkTo.home(),
                newStep: AppCurrentStep.Start
            };
    }
}

export const getNumberOfCurrentStep = (currentStep: AppCurrentStep | null, controller?: AppControllerState) => {
    switch (currentStep) {
        case AppCurrentStep.Calculation:
            return 1
        case AppCurrentStep.CalculationSummary:
            return 2
        case AppCurrentStep.Verification:
            return 3
        case AppCurrentStep.VerificationSummary:
            return 3
        case AppCurrentStep.VerificationQuestions:
            return 4
        case AppCurrentStep.Purpose:
            return 5
        // case AppCurrentStep.PurposeRepeat:
        //     return 5
        case AppCurrentStep.SpendingReport:
            return 6
        // case AppCurrentStep.Address:
        //     return 7
        case AppCurrentStep.RegisterAccount:
            return 7
        case AppCurrentStep.SendRequest:
            return 8
        case AppCurrentStep.VerificationQuestionsFail:
            if (controller?.previous_step === AppCurrentStep.CalculationSummary) {
                return 2
            } else if (controller?.previous_step === AppCurrentStep.Start) {
                return 3
            } else {
                return 9
            }
        default:
            return 9
    }
}

export const getButtonName = (currentStep: AppCurrentStep | null) => {
    switch (currentStep) {
        case AppCurrentStep.Calculation:
            return 'MÁM VYBRÁNO'
        case AppCurrentStep.CalculationSummary:
            return 'POTVRZUJI'
        case AppCurrentStep.SendRequest:
            return 'ODESLAT ŽÁDOST'
        case AppCurrentStep.VerificationQuestionsFail:
            return 'NASHLEDANOU'
        default:
            return 'POKRAČUJ'
    }
}

export const getVerificationResult = (result: boolean | null, type: verificationType) => {
    switch (result) {
        case true:
            switch (type) {
                case verificationType.BLACK_LIST:
                    return {
                        image: LustrationGreen,
                        classes: "lustration-green",
                        text: "Nemáte"
                    }
                case verificationType.INSOLVENCY:
                    return {
                        image: LustrationGreen,
                        classes: "lustration-green",
                        text: "Nejste"
                    }
                default:
                    return {
                        image: LustrationGreen,
                        classes: "lustration-green",
                        text: "V pořádku"
                    }
            }
        case false:
            switch (type) {
                case verificationType.BLACK_LIST:
                    return {
                        image: LustrationRed,
                        classes: "lustration-red",
                        text: "Máte"
                    }
                case verificationType.INSOLVENCY:
                    return {
                        image: LustrationRed,
                        classes: "lustration-red",
                        text: "Jste"
                    }
                default:
                    return {
                        image: LustrationRed,
                        classes: "lustration-red",
                        text: "V nepořádku"
                    }
            }
        case null:
            return {
                image: LustrationNeutral,
                classes: "lustration-neutral",
                text: "Nezjištěno"
            }
        default:
            return undefined;
    }
};

export const getGtmBtnId = (currentStep: AppCurrentStep | null) => {
    switch (currentStep) {
        case AppCurrentStep.CalculationSummary:
            return "Potvrzeni-vyse-uveru-a-doby-splatnosti"
        case AppCurrentStep.VerificationQuestions:
            return "exekuce-trvalyPobyt-osobniBankrot"
        case AppCurrentStep.Purpose:
            return "ucel-jineMoznosti-opakovanyUver"
        // case AppCurrentStep.PurposeRepeat:
        //     return "ucel-jineMoznosti-opakovanyUver"
        case AppCurrentStep.SpendingReport:
            return "mesicni-zisk"
        // case AppCurrentStep.Address:
        //     return "korespondencni-adresa"
        case AppCurrentStep.RegisterAccount:
            return "kontaktni-udaje"
        case AppCurrentStep.SendRequest:
            return "souhlas-se-spracovanim-udaju-a-odeslani-zadosti"
        default:
            return null;
    }
}


