import React, {useEffect, useState} from 'react';
import Crossroad from "assets/UserAssets/crossroad.svg";
import ViewContainer from "components/ViewContainer";
import ImageCol from "components/ImageCol";
import ContentCol from "components/ContentCol";
import {useForm} from "react-hook-form";
import PurposeItemContainer from "pages/purpose/components/PurposeItemContainer";
import PurposeItem from "pages/purpose/components/PurposeItem";
import InputElement from "components/InputElement";
import {LoanPurpose} from "types/entities";
import {useTypedSelector} from "helpers/reducers";
import {useDispatch} from "useDispatch";
import LoanAction from "store/loan/LoanAction";
import ScrollToTop from "components/ScrollToTop";

const Purpose = props => {
    const loanPurpose: LoanPurpose = useTypedSelector(state => state.loanController.purpose);
    const [activePurposeValue, setActivePurposeValue] = useState(loanPurpose.purpose || "");
    // const [activeOptionsValue, setActiveOptionsValue] = useState(loanPurpose.applied_elsewhere || "");
    const [isPurposeInputShow, setIsPurposeInputShow] = useState(false);
    const [isAppliedElsewhereInputShow, setIsAppliedElsewhereInputShow] = useState(false);
    const dispatch = useDispatch();
    const defaultValues = {
        purpose_text: loanPurpose.purpose_text,
        applied_elsewhere_text: loanPurpose.applied_elsewhere_text
    }
    const { register, handleSubmit, setError, formState: { errors } } = useForm({
        defaultValues: defaultValues
    });


    useEffect(() => {
        if (activePurposeValue === "4" || loanPurpose.purpose === "4") {
            setIsPurposeInputShow(true);
        } else {
            setIsPurposeInputShow(false);
        }

        // if (activeOptionsValue === "Ano - (uveďte kde a jakou částku)" || loanPurpose.applied_elsewhere === 'Ano - (uveďte kde a jakou částku)') {
        //     setIsAppliedElsewhereInputShow(true);
        // } else {
        //     setIsAppliedElsewhereInputShow(false);
        // }
    }, [activePurposeValue]);  // , activeOptionsValue

    const onChangePurposeHandler = (e) => {
        dispatch(LoanAction.setLoanPurpose({name: "purpose_text", value: e.target.value}));
        if (e.target.value === "") {
            dispatch(LoanAction.setLoanPurpose({name: "purpose_text", value: null}))
        }
    }

    const onChangeAppliedElsewhereHandler = (e) => {
        dispatch(LoanAction.setLoanPurpose({name: "applied_elsewhere_text", value: e.target.value}));
        if (e.target.value === "") {
            dispatch(LoanAction.setLoanPurpose({name: "applied_elsewhere_text", value: null}))
        }
    }


    return (
        <ViewContainer class={"negative-mt-20"}>
            <ImageCol lg={3} image={Crossroad}/>
            <ContentCol lg={8}>
                {/* <ul className="calcSummary-list Question-list Purpose-list"> */}
                    <PurposeItemContainer
                        title={<>Na <b>jaký účel</b> použijete finance z úvěru?</>}
                        subtitle={'Účel nerozhoduje o poskytnutí úvěru!'}>
                        <PurposeItem
                            name={'purpose1'}
                            value={"0"}
                            objectItem={'purpose'}
                            label={'Pracuji na novém projektu'}
                            activeValue={activePurposeValue}
                            setActiveValue={setActivePurposeValue}/>
                        <PurposeItem
                            name={'purpose1'}
                            value={"1"}
                            objectItem={'purpose'}
                            label={'Rozvíjím své podnikání'}
                            activeValue={activePurposeValue}
                            setActiveValue={setActivePurposeValue}/>
                        <PurposeItem
                            name={'purpose1'}
                            value={"2"}
                            objectItem={'purpose'}
                            label={'Potřebuji nakoupit zboží nebo služby'}
                            activeValue={activePurposeValue}
                            setActiveValue={setActivePurposeValue}/>
                        <PurposeItem
                            name={'purpose1'}
                            value={"3"}
                            objectItem={'purpose'}
                            label={'Potřebuji uhradit své závazky'}
                            activeValue={activePurposeValue}
                            setActiveValue={setActivePurposeValue}/>
                        <PurposeItem
                            name={'purpose1'}
                            value={"4"}
                            objectItem={'purpose'}
                            label={'Jiné - (uveďte o co se jedná)'}
                            activeValue={activePurposeValue}
                            setActiveValue={setActivePurposeValue}/>
                        {isPurposeInputShow && (
                            <div className={"address__input"}>
                                <InputElement register={register} name={'purpose_text'} label={''} onChange={onChangePurposeHandler}/>
                            </div>
                        )}
                    </PurposeItemContainer>

                    {/* <PurposeItemContainer title={<>Zkoušel(a) jste <b>i jiné možnosti získání financí?</b></>}>
                        <PurposeItem
                            name={'purpose2'}
                            value={'Ano - (uveďte kde a jakou částku)'}
                            objectItem={'applied_elsewhere'}
                            label={'Ano - (uveďte kde a jakou částku)'}
                            activeValue={activeOptionsValue}
                            setActiveValue={setActiveOptionsValue}/>
                        {isAppliedElsewhereInputShow && (
                            <div className={"address__input"}>
                                <InputElement register={register} name={'applied_elsewhere_text'} label={''} onChange={onChangeAppliedElsewhereHandler}/>
                            </div>
                        )}
                        <PurposeItem
                            name={'purpose2'}
                            value={'Ne'}
                            objectItem={'applied_elsewhere'}
                            label={'Ne'}
                            activeValue={activeOptionsValue}
                            setActiveValue={setActiveOptionsValue}/>
                    </PurposeItemContainer> */}
                {/* </ul> */}
            </ContentCol>
        </ViewContainer>
    );
};

export default Purpose;