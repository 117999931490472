import React, {useEffect, useState} from 'react';
import BackArrow from "assets/UserAssets/backArrow.svg";
import Button from "components/Button";
import FooterNavItem from "components/Footer/components/FooterNavItem";
import {RouteComponentProps, useLocation, withRouter} from "react-router";
import {useTypedSelector} from "helpers/reducers";
import {useDispatch} from "useDispatch";
import AppControllerAction from "store/appControler/AppControllerAction";
import {getButtonName, getGtmBtnId, getNextStep, getNumberOfCurrentStep, getPrevStep} from "helpers/utils";
import {AppCurrentStep} from "types/enums/AppCurrentStep";
import clsx from "clsx";
import {SessionControllerState} from "store/sessionController/SessionControllerReducer";
import {LoanReducerState} from "store/loan/LoanReducer";
import LinkTo from "helpers/LinkTo";
import {openModal} from "store/action";
import VerificationOfferModal from "pages/verification/components/VerificationOfferModal";
import {resetAppState} from "helpers/conifg";
import LoanAction from "store/loan/LoanAction";
import FinishVerificationModal from "pages/finish/components/FinishVerificationModal";

interface Params {}

interface FooterProps extends RouteComponentProps<Params> {}

const Footer = (props: FooterProps) => {
    const appController = useTypedSelector(state => state.appController);
    const sessionController: SessionControllerState = useTypedSelector(state => state.sessionController);
    const loanController: LoanReducerState = useTypedSelector(state => state.loanController);
    const [isArrowShow, setIsArrowShow] = useState(true);
    const [isFooterShow, setIsFooterShow] = useState(true);
    const [failed, setFailed] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [gtmBtnId, setGtmBtnId] = useState<string | null>(null);
    const location = useLocation();
    const dispatch = useDispatch();
    const openOfferModal = () => dispatch(openModal(<VerificationOfferModal/>, {}))


    // handle arrow showing
    useEffect(() => {
        if (appController.current_step === AppCurrentStep.Calculation) {
            setIsArrowShow(false);
        } else {
            setIsArrowShow(true);
        }

        if (appController.current_step === AppCurrentStep.Finish) {
            setIsArrowShow(false);
            setIsFooterShow(false);
        }

        if (appController.current_step === AppCurrentStep.VerificationQuestionsFail) {
            setIsArrowShow(false);
        }
        if (location.pathname === '/zamitnuto') {
            setCurrentStep(2);
        } else {
            setCurrentStep(getNumberOfCurrentStep(appController.current_step, appController));
            setGtmBtnId(getGtmBtnId(appController.current_step))
        }
    }, [appController.current_step]);


    // handling button disabling
    useEffect(() => {
        if (appController.current_step === AppCurrentStep.VerificationQuestions) {
            if (sessionController.verificationQuestions?.has_office_address !== undefined
                && sessionController.verificationQuestions?.has_execution !== undefined
                && sessionController.verificationQuestions?.has_insolvency !== undefined) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        } else if (appController.current_step === AppCurrentStep.Purpose) { /// purpose verify question
            if (loanController.purpose.purpose !== null) {
                // && loanController.purpose.applied_elsewhere !== null) {

                if (loanController.purpose.purpose === "4" && loanController.purpose.purpose_text === null) { /// if checked option === others
                    setDisabled(true);
                } 
                // else  if (loanController.purpose.applied_elsewhere !== "Ne" && loanController.purpose.applied_elsewhere_text === null) {
                //     setDisabled(true);
                // }
                else {
                    setDisabled(false);
                }

            } else {
                setDisabled(true);
            }
        } 
        // else if (appController.current_step === AppCurrentStep.PurposeRepeat) {
        //     if (loanController.purpose.recurring_debt !== null) {
        //         setDisabled(false);
        //     } else {
        //         setDisabled(true);
        //     }
        // }
        else if (appController.current_step === AppCurrentStep.SpendingReport) {
            if (sessionController.spendingReport.income !== null && sessionController.spendingReport.income !== ""
                && sessionController.spendingReport.expenses !== null && sessionController.spendingReport.expenses !== ""
                && sessionController.spendingReport.other_income !== null && sessionController.spendingReport.other_income !== "" && sessionController.spendingReport.loan_payments !== null) {
                if (+sessionController.spendingReport.income - sessionController.spendingReport.expenses > 0) {
                    setDisabled(false);
                } else {
                    setDisabled(true);
                }
            } else {
                setDisabled(true);
            }
        } 
        // else if (appController.current_step === AppCurrentStep.Address) {
        //     if (sessionController.mailingAddress.has_mailing_address) {
        //         if (sessionController.mailingAddress.address === "" || sessionController.mailingAddress.address === null) {
        //             setDisabled(true);
        //         } else {
        //             setDisabled(false)
        //         }
        //     } else if (sessionController.mailingAddress.has_mailing_address === false) {
        //         setDisabled(false);

        //     } else {
        //         setDisabled(true);
        //     }
        // } 
        else if (appController.current_step === AppCurrentStep.RegisterAccount) {
            if ((sessionController.userAccount.email !== null && sessionController.userAccount.email !== "")
                && (sessionController.userAccount.phone_number !== null && sessionController.userAccount.phone_number !== "")
                && (sessionController.userAccount.password !== null && sessionController.userAccount.password !== "")
                && (sessionController.userAccount.password_repeat !== null && sessionController.userAccount.password_repeat !== "")) {
                setDisabled(false);
            } else {

                setDisabled(true);
            }
        } else if (appController.current_step === AppCurrentStep.SendRequest) {
            if (loanController.sendRequest.personalDataMarketing
                && loanController.sendRequest.personalData
                && loanController.sendRequest.repi) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        } else {
            setDisabled(false);
        }
    }, [appController.current_step, sessionController, loanController]);


    // handler verification questions
    useEffect(() => {
        if (appController.current_step === AppCurrentStep.VerificationQuestions) {
            if (sessionController.verificationQuestions?.has_insolvency
                || sessionController.verificationQuestions?.has_execution
                || sessionController.verificationQuestions?.has_office_address) {
                setFailed(true);
            } else {
                setFailed(false)
            }
        }
    }, [sessionController.verificationQuestions]);


    //handle next step
    const onNextStepHandler = () => {
        if (appController.current_step === AppCurrentStep.VerificationQuestions) {
            if (failed
                || (
                sessionController.verificationQuestions?.terminate?.has_execution
                || sessionController.verificationQuestions?.terminate?.has_insolvency
                || sessionController.verificationQuestions?.terminate?.has_office_address)  // @TODO terminate test
            ) {
                dispatch(AppControllerAction.setCurrentStep(AppCurrentStep.VerificationQuestionsFail));
                props.history.push(LinkTo.verificationFailed());
                return false;
            }

            if (loanController.loan.loan_selection.selected_price > 4000) {
                openOfferModal();
                return false;
            }

            //handle lustrations
            if (!sessionController.verificationQuestions?.terminate?.has_execution
                || !sessionController.verificationQuestions?.terminate?.has_insolvency
                || !sessionController.verificationQuestions?.terminate?.has_office_address) {
                dispatch(openModal(<FinishVerificationModal/>, {}));
                return false;
            }

        } else if (appController.current_step === AppCurrentStep.Finish) {
            return false;
        } else if (appController.current_step === AppCurrentStep.VerificationQuestionsFail) {
            resetAppState(appController, loanController, sessionController, props.history, location, dispatch);
            props.history.push(LinkTo.home());
            return false;
        } else if (appController.current_step === AppCurrentStep.SendRequest) {
            let leads = {}

            if (appController?.espoluprace?.t_id !== null) {
                leads = {
                    ext_id: appController.espoluprace.t_id === 'admitad' ? appController.espoluprace.a_aid : appController.espoluprace.t_id,
                    origin: appController.espoluprace.t_id === 'admitad' ? 17 : appController.espoluprace.origin
                }
            }

            if (appController.leadgenia?.cid !== null) {
                leads = {ext_id: appController.leadgenia.cid, origin: appController.leadgenia.origin}
            }

            if (appController?.espoluprace?.t_id === null && appController.leadgenia?.cid === null) {
                leads = {origin: 0}
            }


            const data = {
                loan: {
                    amount: parseInt(loanController.loan.loan_selection.selected_price),
                    duration: parseInt(loanController.loan.loan_selection.selected_days),
                    purpose: loanController.purpose.purpose,
                    purpose_text: loanController.purpose.purpose === 4 ? loanController.purpose.purpose_text : null,
                },
                customer: {
                    email: sessionController.userAccount.email,
                    password: sessionController.userAccount.password,
                    birth_number: sessionController.user.birth_number,
                    first_name: sessionController.user.first_name,
                    last_name: sessionController.user.last_name,
                    income: parseInt(sessionController.spendingReport.income),
                    expenses: parseInt(sessionController.spendingReport.expenses),
                    loan_payments: parseInt(sessionController.spendingReport.loan_payments),
                    other_income: sessionController.spendingReport.other_income,
                    phone_number: sessionController.userAccount.phone_number,
                    google_analytics_id: sessionController.gaId,
                    mailing_address: {
                        city: sessionController.mailingAddress.has_mailing_address ? sessionController.mailingAddress.city : null,
                        street: sessionController.mailingAddress.has_mailing_address  ? sessionController.mailingAddress.street : null,
                        house_number: sessionController.mailingAddress.has_mailing_address ? sessionController.mailingAddress.house_number : null,
                        postal_code: sessionController.mailingAddress.has_mailing_address ? sessionController.mailingAddress.postal_code : null,
                    },
                    recurring_debt: loanController.purpose.recurring_debt !== "Ne",
                    applied_elsewhere: loanController.purpose.applied_elsewhere !== "Ne",
                    applied_elsewhere_text: loanController.purpose.applied_elsewhere !== "Ne" ? loanController.purpose.applied_elsewhere_text : null,
                    ...leads,
                },
                agreements: {
                    personal_data: loanController.sendRequest.personalData,
                    marketing: loanController.sendRequest.personalDataMarketing,
                    repi: loanController.sendRequest.repi,
                }
            }
            dispatch(LoanAction.sendRequest(data)).then(r => {
                dispatch(AppControllerAction.setEspoluprace({
                    a_aid: null,
                    t_id: null,
                    origin: 13,
                }))
                dispatch(AppControllerAction.setLeadGenia({cid: null, origin: 18}))
            });
        }

        const nextStep = getNextStep(appController.current_step);
        dispatch(AppControllerAction.setCurrentStep(nextStep.newStep))
        props.history.push(nextStep.page);
    }


    // handler previous step
    const onPrevStepHandler = () => {
        const prevStep = getPrevStep(appController.current_step);
        dispatch(AppControllerAction.setCurrentStep(prevStep.newStep))
        props.history.push(prevStep.page);
    }

    return (
        <>
            {isFooterShow && (
                <div className={clsx("footerProgress d-flex", appController.current_step === AppCurrentStep.Calculation && 'spaceMobile' )}>
                    <div
                        className={clsx("backArrow desktopOnly-nav", !isArrowShow && "pointer-events-none invisible")}
                        onClick={onPrevStepHandler}>
                        <img src={BackArrow} alt="back arrow"/>
                    </div>
                    <ul className={"footerProgress-list DesktopOnly"}>
                        <FooterNavItem class={currentStep <= 4 ? 'activeStatus' : 'finishedStatus'}>Žádost</FooterNavItem>
                        <FooterNavItem class={currentStep > 4 && currentStep <= 6 ? 'activeStatus' : currentStep > 4 ? 'finishedStatus' : ''}>Účel poskytnutí</FooterNavItem>
                        <FooterNavItem class={currentStep > 6 && currentStep === 7 ? 'activeStatus' : currentStep > 6 ? 'finishedStatus' : ''}>Doplňující údaje</FooterNavItem>
                        <FooterNavItem class={currentStep > 7 && currentStep === 8 ? 'activeStatus' : currentStep > 8 ? 'finishedStatus' : ''}>Odeslání žádosti</FooterNavItem>
                        <FooterNavItem class={currentStep > 8 && currentStep === 9 ? 'activeStatus' : currentStep > 8 ? 'finishedStatus' : ''}>Ověření identity</FooterNavItem>
                    </ul>
                    <Button
                            class={"btn-purple"}
                            onClick={onNextStepHandler}
                            dataGTMid={gtmBtnId}
                            disabled={disabled}
                    >
                        <span>{getButtonName(appController.current_step)}</span>
                    </Button>
                </div>
            )}
        </>
    );
};

export default withRouter(Footer);