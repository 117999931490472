import React, {useEffect, useState} from "react";

import './Layout.scss'
import Toolbar from '../components/Toolbar';
import SideDrawer from 'components/SideDrawer';
import MessengerCustomerChat from "react-messenger-customer-chat";
import {Container} from "react-bootstrap";
import Footer from "components/Footer/Footer";
import {useTypedSelector} from "helpers/reducers";
import {AppStatus} from "types/enums/AppStatus";
import {useLocation} from "react-router";
import {AppCurrentStep} from "types/enums/AppCurrentStep";
import Modal from "components/Modal";
import MobileProgressNav from "components/MobileProgressNav";
import clsx from "clsx";
import Gleap from 'gleap'

const Layout = props => {
    const [sideDrawerIsVisible, setSideDrawerIsVisible] = useState(false);
    const [isFooterShow, setIsFooterShow] = useState(false);
    const appController = useTypedSelector(state => state.appController);
    const [isMobileProgressNavShow, setIsMobileProgressNavShow] = useState(false)
    const modal = useTypedSelector(state => state.modal);
    const location = useLocation();

    useEffect(() => {
        Gleap.showFeedbackButton(true)
    }, [])

    useEffect(() => {
        // @ts-ignore
        if ((appController.current_step !== AppCurrentStep.Start
            && appController.current_step !== AppCurrentStep.Verification
            && appController.current_step !== AppCurrentStep.VerificationSummary
            && appController.current_step !== AppCurrentStep.Finish)
            && (appController.app_status === AppStatus.InProgress && location.pathname !== '/')
            || (appController.app_status === AppStatus.Error && appController.current_step === AppCurrentStep.VerificationQuestionsFail)) {
            setIsFooterShow(true);
        } else {
            // alert('footes is not showing')
            setIsFooterShow(false);
        }
    }, [appController.current_step, appController.app_status, location.pathname]);

    useEffect(() => {
        if (appController.app_status === AppStatus.Started
            || appController.current_step === AppCurrentStep.Verification
            || appController.current_step === AppCurrentStep.VerificationSummary) {
            setIsMobileProgressNavShow(false);
        } else {
            setIsMobileProgressNavShow(true);
        }
    }, [appController.app_status, appController.current_step]);


    const sideDrawerClosedHandler = () => {
        setSideDrawerIsVisible(false);
    };

    const sideDrawerToggleHandler = () => {
        setSideDrawerIsVisible(!sideDrawerIsVisible);
    };

    return (
        <div className={'root-wrap position-relative h-100'}>
            <Container fluid className={clsx("wrapper d-flex flex-column justify-content-between pb-3", modal.isOpen && "hw-100")}>
                <Toolbar visible={sideDrawerIsVisible} closeDrawer={sideDrawerClosedHandler} drawerToggleClicked={sideDrawerToggleHandler} />
                <SideDrawer
                    open={sideDrawerIsVisible}
                    closed={sideDrawerClosedHandler}/>
                {isMobileProgressNavShow && <MobileProgressNav/>}
                <Container className={'container-section'}>
                    {props.children}
                </Container>
                {isFooterShow && <Footer/>}
            </Container>
            {appController.app_status === AppStatus.Started && (
                <MessengerCustomerChat
                    pageId={"522872041394148"}
                    appId={"770423046938388"}
                    htmlRef={window.location.pathname}
                    shouldShowDialog={false}
                    minimized={true}
                    greetingDialogDisplay={'show'}
                    greetingDialogDelay={0}
                />
            )}
            <Modal/>
        </div>
    );
};

export default Layout;