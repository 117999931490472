import React from 'react';
import Button from "components/Button";

interface ActionButtonsProps {
    prevStep: () => void,
    nextStep: () => void,
    nextStepDisabled: boolean,
}

const ActionButtons = (props: ActionButtonsProps) => {
    return (
        <div className="actionButtons d-flex flex-wrap justify-content-center">
            <Button
                class={"btn-main btn-white d-flex flex-column justify-content-center align-items-center"}
                onClick={props.prevStep}>
                <span className={'text-center'}>Ne, údaje nesouhlasí</span>
                <span className="small text-center">Opravím to</span>
            </Button>
            <Button
                class={"btn-purple d-flex flex-column justify-content-center align-items-center"}
                dataGTMid="ano-jsem-to-ja"
                disabled={props.nextStepDisabled}
                onClick={props.nextStep}>
                <span className={'text-center'}>Ano, jsem to já</span>
                <span className="small text-center">Pokračovat dále</span>
            </Button>
        </div>
    );
};

export default ActionButtons;