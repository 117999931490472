import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import store from "helpers/store";

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router} from "react-router-dom";
import Loader from "components/Loader";
import ScrollToTop from "components/ScrollToTop";
import Analytics from "helpers/Analytics";
import Gleap from 'gleap'

Gleap.initialize('deWnbTB56G85d7NcP9rOtBlvpFFPZVEa')
Gleap.setLanguage('cs')


// send gtm page views
export const HOST = "vistacredit.cz";
export const HOSTNAME = "www.vistacredit.cz";

declare global {
    interface Window {
        dataLayer: any;
    }
}

localStorage.setItem('terminate', "false");

const Root: React.FC = () => {
    return (
        <Suspense fallback={<Loader/>}>
            <Provider store={store}>
                <React.StrictMode>
                    <Router>
                        <ScrollToTop/>
                        <Analytics/>
                        <App/>
                    </Router>
                </React.StrictMode>
            </Provider>
        </Suspense>
    )
};

ReactDOM.render(<Root/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
