import React, {useEffect} from 'react';
import PiggyBank from "assets/UserAssets/piggyBank.svg";
import ViewContainer from "components/ViewContainer";
import ImageCol from "components/ImageCol";
import ContentCol from "components/ContentCol";
import SpendingReportInput from "pages/purpose/components/SpendingReportInput";
import {useForm} from "react-hook-form";
import SpendingReportArrowInput from "pages/purpose/components/SpendingReportArrowInput";
import Question from "pages/verification/components/Question";
import {useDispatch} from "useDispatch";
import SessionControllerAction from "store/sessionController/SessionControllerAction";
import {useTypedSelector} from "helpers/reducers";
import Information from "components/Information";

const SpendingReport = props => {
    const dispatch = useDispatch();
    const spendingReport = useTypedSelector(state => state.sessionController.spendingReport);

    const defaultValues = spendingReport ? {
        ...spendingReport
    } : {}

    const { register, handleSubmit,setValue, watch, setError, formState: { errors } } = useForm({
        defaultValues: defaultValues
    });

    useEffect(() => {
        if (+spendingReport?.income < +spendingReport?.expenses) {
            dispatch(SessionControllerAction.setUserSpendingReport({
                name: 'expenses',
                value: spendingReport?.income
            }));
            setValue('expenses', spendingReport?.income);
        }

        if (+spendingReport?.expenses < +spendingReport?.loan_payments) {
            dispatch(SessionControllerAction.setUserSpendingReport({
                name: 'loan_payments',
                value: spendingReport?.expenses
            }))
            setValue('loan_payments', spendingReport?.expenses);

        }
    }, [spendingReport?.expenses, spendingReport?.loan_payments]);

    const onQuestionAnswerHandler = (e, question, answer) => {
        dispatch(SessionControllerAction.setUserSpendingReport({
            name: 'other_income',
            value: answer
        }))
    }

    const onChangeHandler = (question, value) => {
        dispatch(SessionControllerAction.setUserSpendingReport({
            name: question,
            value: value
        }));
    }

    return (
        <ViewContainer class={"pt-4 pt-lg-0"}>
            <ImageCol lg={3} image={PiggyBank}/>
            <ContentCol lg={8}>
                <form>
                    <ul className="calcSummary-list Question-list SpendingReport-list">
                        <SpendingReportInput
                            title={<>Váš <b>měsíční zisk</b> (celkový příjem)</>}
                            register={register}
                            name={'income'}
                            onChange={onChangeHandler}/>
                        <SpendingReportArrowInput
                            onChange={onChangeHandler}
                            title={<>Vaše <b>měsíční náklady</b> (výdej za všechno)</>} register={register}/>

                        <li className="calcSummary-listItem Question-listItem SpendingReport-listItem" style={{borderBottom: "none", paddingBottom: '0px'}}>
                            <div className="SpendingReport-result d-flex justify-content-between">
                                <h2 className="spendingReportResultHeading"><b>Vaše volné prostředky pro splacení tohoto úvěru jsou:</b></h2>
                                <h2 className="spendingReportResultAmount"><b>{+spendingReport?.income - +spendingReport?.expenses > 0 ? +spendingReport?.income - +spendingReport?.expenses : 0} Kč</b></h2>
                            </div>

                            <Question onClick={onQuestionAnswerHandler}
                                      question={'Mám ještě jiný příjem'}
                                      questionName={'other_income'}
                                      type={'other_income'}
                                      successMessage={"Super, vždycky je dobré mít peníze navíc :-)"}
                                      failMessage={"Dobře, i tak to snad bude stačit?"}/>

                        </li>
                        <Information
                            text={<><b>Splatnost úvěru je až 70 dnů</b></>}/>
                    </ul>
                </form>
            </ContentCol>
        </ViewContainer>
    );
};

export default SpendingReport;