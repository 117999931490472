import React, { useCallback, useEffect, useState} from 'react';
import axios, {AxiosInstance} from "axios";
import _, { debounce } from "lodash";

const useFoxentryAddressWhisper = (inputValue?: string, type?: 'lookup' | 'whisper', url?: string, options?: any) => {
    const [response, setResponse] = useState<any>(null);
    const [showWhisperer, setShowWhisperer] = useState<boolean>(false);

    console.log("Foxentry API Domain:", process.env.REACT_APP_FOXENTRY_API_DOMAIN);

    useEffect(() => {
        if (inputValue && type === "whisper") {
            fetchAddress(url, inputValue, options);
        }
    }, [inputValue, type, url, options]);

    const foxentryAxiosInstance = (): AxiosInstance => {
        const foxentry = axios.create({
            baseURL: process.env.REACT_APP_FOXENTRY_API_DOMAIN
        });

        return foxentry;
    }

    const foxentry = foxentryAxiosInstance();

    const validateEmail = async (query: string) => {
        try {
            const { data } = await foxentry.post('/email/validate', {
                apiKey: process.env.REACT_APP_FOXENTRY_API_KEY,
                request: {
                    query: { email: query },
                    options: {
                        validationType: 'extended',
                        acceptDisposableEmails: true,
                        acceptFreemails: true
                    }
                }
            });
    
            const result = data?.response?.result;
    
            if (!result) {
                console.error("Invalid response from API:", data);
                return false;
            }
    
            const { isValid, flags } = result;
            return isValid;
        } catch (error) {
            console.error("Email validation error:", error);
            return false;
        }
    };


    const validatePhone = async (query: string) => {
        try {
            const response = await foxentry.post('/phone/validate', {
                apiKey: process.env.REACT_APP_FOXENTRY_API_KEY,
                request: {
                    query: {number: query, prefix: '+420'},
                    options: {validationType: 'basic', formatNumber: false, }
                }
            })
            return response.data.response.result.isValid
        } catch(error) {
            console.log(error)
        }
    }

    const validateName = async (query: {key: string, value?: string}) => {
        try {
            const response = await foxentry.post('/name/validate', {
                apiKey: process.env.REACT_APP_FOXENTRY_API_KEY,
                request: {
                    query: {[query.key]: query.value},
                    options: {
                        dataScope: 'basic',
                        dataSource: ['CZ'],
                        acceptDegrees: true,
                        acceptContext: true,
                        validationDepth: 'minimal',
                        smartMode: false
                    }
                }
            })
            setResponse({type: query.key, results: response.data.response.suggestions.map(suggesstion => suggesstion.data)})
            setShowWhisperer(true)
        } catch(error) {
            console.log(error)
        }

    }

    const fetchAddress = (url: string = "/location/search", query: any, options = { dataScope: "basic" }) => {
        foxentry.post(url, {
            apiKey: process.env.REACT_APP_FOXENTRY_API_KEY,
            request: {
                query: {
                    type: "streetWithNumber",
                    value: query,
                    filter: { country: "CZ" }
                },
                options: {
                    ...options
                }
            }
        })
        .then((r: any) => {
            if (r.data?.response?.results) {
                setResponse(r.data.response.results.map(item => item.data));
                setShowWhisperer(true);
            } else {
                console.warn("No results in API response", r.data);
                setShowWhisperer(false);
            }
        })
        .catch((err) => {
            console.error("Address fetch error:", err);
            setShowWhisperer(false);
        });
    };

    const debounceEventHandler = useCallback(
        debounce((callback, value) => {
            if (value) {
                callback(value);
            }
        }, 550),
        []
    );

    return {
        response,
        showWhisperer,
        setShowWhisperer,
        debounceEventHandler,
        fetchAddress,
        validateName,
        validatePhone,
        validateEmail
    }
}

export default useFoxentryAddressWhisper;